import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Log from "../components/Log";
import { UidContext } from "../components/AppContext";
import { Prevention } from "../components/Prevention";
import { Introduction } from "../components/Introduction";
import Logout from "../components/Log/Logout";
import axios from "axios";

export const Connexion: React.FC = () => {
  const uid = useContext(UidContext);
  const pseudo = uid?.pseudo;
  const email = uid?.email;
  const uidControl = uid?.uid;
  const abo = uid?.Abo;

  const [language, setLanguage] = useState<"fr" | "en">("fr");

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang !== "fr-FR" && userLang !== "fr") {
      setLanguage("en");
    }
  }, []);

  const translations = {
    fr: {
      welcome: `Bienvenue ${pseudo} !`,
      email: `Email: ${email}`,
      subscription: `Abonnement: ${abo ? "Actif" : "Inactif"}`,
      modelInfo: `Notre modèle actuel obtient une précision de 68,9% sur les données d'entraînement (80 000) et 68,83% sur les données de test. Nous utilisons un XGBoost, une régression logistique et un réseau de neurones profonds (DNN) avec un système de vote.`,
      xgboost: `XGBoost : XGBoost est un algorithme de machine learning basé sur les arbres de décision. Il est connu pour sa performance et son efficacité dans les compétitions de machine learning. Il fonctionne en construisant plusieurs arbres de décision en séquence et en optimisant les prédictions en combinant les résultats de ces arbres.`,
      logisticRegression: `Régression Logistique : La régression logistique est un modèle statistique utilisé pour la classification binaire. Il est utile pour prédire la probabilité qu'un événement se produise (comme un match gagné ou perdu) en fonction des valeurs des variables explicatives.`,
      dnn: `Réseau de Neurones Profonds (DNN) : Un réseau de neurones profonds est un modèle de machine learning inspiré du cerveau humain, composé de plusieurs couches de neurones artificiels. Ces réseaux sont capables de capturer des relations complexes dans les données grâce à leur architecture multicouche.`,
      ensembleLearning: `Système de Vote : Le système de vote (ou "ensemble learning") combine les prédictions de plusieurs modèles pour améliorer la performance globale. Chaque modèle (XGBoost, régression logistique, DNN) fait une prédiction, et le résultat final est déterminé en fonction de la majorité des votes. Cette approche permet de réduire les erreurs individuelles des modèles et d'améliorer la précision des prédictions.`,
      wisdomCrowds: `Théorie de la Sagesse des Foules : Cette théorie suggère que la combinaison des opinions de plusieurs personnes ou modèles peut conduire à une décision meilleure que celle prise par une seule personne ou modèle. En appliquant cette théorie à notre modèle, nous combinons les prédictions de plusieurs algorithmes différents pour obtenir une meilleure précision globale.`,
      complementaryModels: `Modèles Complémentaires : Les modèles utilisés (XGBoost, régression logistique, DNN) sont suffisamment différents dans leur approche et leur fonctionnement. Cette diversité permet à chaque modèle de capturer différents aspects des données et de compenser les faiblesses des autres modèles. En les combinant, on obtient une prédiction plus robuste et fiable.`,
      viewMatches: "Voir les matches",
      betHistory: "Historique des paris",
      changePassword: "Changer le mot de passe",
      unsubscribe: "Se désabonner",
    },
    en: {
      welcome: `Welcome ${pseudo}!`,
      email: `Email: ${email}`,
      subscription: `Subscription: ${abo ? "Active" : "Inactive"}`,
      modelInfo: `Our current model achieves an accuracy of 68.9% on training data (80,000) and 68.83% on test data. We use XGBoost, logistic regression, and a deep neural network (DNN) with an ensemble learning system.`,
      xgboost: `XGBoost: XGBoost is a machine learning algorithm based on decision trees. It is known for its performance and efficiency in machine learning competitions. It works by building multiple decision trees in sequence and optimizing predictions by combining the results of these trees.`,
      logisticRegression: `Logistic Regression: Logistic regression is a statistical model used for binary classification. It is useful for predicting the probability of an event occurring (such as a match won or lost) based on the values of explanatory variables.`,
      dnn: `Deep Neural Network (DNN): A deep neural network is a machine learning model inspired by the human brain, composed of multiple layers of artificial neurons. These networks can capture complex relationships in data thanks to their multi-layered architecture.`,
      ensembleLearning: `Ensemble Learning System: The ensemble learning system combines the predictions of multiple models to improve overall performance. Each model (XGBoost, logistic regression, DNN) makes a prediction, and the final result is determined based on the majority of votes. This approach helps reduce individual model errors and improve prediction accuracy.`,
      wisdomCrowds: `Wisdom of Crowds Theory: This theory suggests that combining the opinions of multiple people or models can lead to a better decision than one made by a single person or model. By applying this theory to our model, we combine the predictions of several different algorithms to achieve better overall accuracy.`,
      complementaryModels: `Complementary Models: The models used (XGBoost, logistic regression, DNN) are sufficiently different in their approach and functioning. This diversity allows each model to capture different aspects of the data and compensate for the weaknesses of other models. By combining them, we obtain a more robust and reliable prediction.`,
      viewMatches: "View Matches",
      betHistory: "Betting History",
      changePassword: "Change Password",
      unsubscribe: "Unsubscribe",
    },
  };

  const {
    welcome,
    email: emailText,
    subscription,
    modelInfo,
    xgboost,
    logisticRegression,
    dnn,
    ensembleLearning,
    wisdomCrowds,
    complementaryModels,
    viewMatches,
    betHistory,
    changePassword,
    unsubscribe,
  } = translations[language];

  const handleUnsubscribe = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/user/unsubscribe`,
        { userId: uidControl }
      );
      alert(response.data.message);
    } catch (error) {
      console.error("Erreur lors de la désinscription :", error);
      alert("Une erreur est survenue lors de la désinscription.");
    }
  };

  return (
    <div>
      <div className="flex justify-left">
        <Introduction />
      </div>

      <div>
        {uidControl ? (
          <div>
            <h1 className="connexion-titre1">{welcome}</h1>
            <p className="connexion-infos">{emailText}</p>
            <p className="connexion-infos">{subscription}</p>

            <div className="custom-link-container">
              <CustomLink to="/" label={viewMatches} />
              <CustomLink to="/Eliza" label={betHistory} />
              <CustomLink to="/change-password" label={changePassword} />

              {abo && (
                <CustomLink
                  to="#"
                  onClick={handleUnsubscribe}
                  label={unsubscribe}
                />
              )}

              <Logout />
            </div>
          </div>
        ) : (
          <div className="profil-page">
            <div className="log-container">
              <Log signin={false} signup={true} />
              <div className="img-container">
                <img
                  src="/images/eliza_rose.png"
                  alt="img-log"
                  style={{
                    maxWidth: "180%",
                    boxShadow: uidControl
                      ? "0 0 15px #FFEB3B, 0 0 30px #FFEB3B, 0 0 45px #FFEB3B"
                      : "0 0 15px #FF00FF, 0 0 30px #FF00FF, 0 0 45px #FF00FF",
                    objectFit: "contain",
                    borderRadius: "180px",
                    marginLeft: uidControl ? "100px" : "100px",
                    border: uidControl
                      ? "3px solid #FFEB3B"
                      : "3px solid #FF00FF",
                    marginTop: uidControl ? "30px" : "",
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-left" style={{ marginTop: "10px" }}>
        <Prevention />
      </div>
    </div>
  );
};

interface CustomLinkProps {
  to: string;
  label: string;
  onClick?: () => void; // Ajout de la possibilité de gérer le click
}

const CustomLink: React.FC<CustomLinkProps> = ({ to, label, onClick }) => (
  <Link
    to={to}

  >
    {label}
  </Link>
);

export default Connexion;
