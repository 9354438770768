import React, { useEffect, useState } from "react";
import { Introduction } from "./Introduction";
import { Prevention } from "./Prevention";

export const Explications = () => {
  const [lang, setLang] = useState('fr');

  useEffect(() => {
    const userLang = navigator.language;
    console.log('Langue détectée par le navigateur:', userLang);
    if (userLang !== 'fr-FR' && userLang !== 'fr') {
      setLang('en');
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML";
    script.async = true;
    document.head.appendChild(script);
  }, []);

  return (
    <section className="mb-5 mt-10" style={{ margin: "0 20px" }}>
      <div>
        <div>
          <Introduction />
        </div>
        <div className="flex justify-left">
          <div>
            <h1
              className="model-titre1"
              style={{
                fontSize: "45px",
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              Bet on Eliza
            </h1>
            {lang === 'fr' ? (
              <p
                style={{
                  fontSize: "22px",
                  marginLeft: "20px",
                  marginTop: "20px",
                  
                  
                }}
              >
                Entraîné sur une base de données comprenant plus de 100 000 matchs
                et testé sur 20 000, notre modèle prend en compte les performances
                passées des joueurs ainsi que des composantes environnementales.
                En accord avec la théorie de la "sagesse des foules", nous avons
                créé une IA composée de trois modèles différents : une régression
                logistique, un modèle XGBoost et un réseau de neurones profonds
                (DNN).
              </p>
            ) : (
              <p
                style={{
                  fontSize: "22px",
                  marginLeft: "20px",
                  marginTop: "30px",
                  
                }}
              >
                Trained on a database of over 100,000 matches and tested on 20,000,
                our model takes into account past player performances as well as
                environmental factors. In line with the theory of "wisdom of the crowds",
                we have created an AI composed of three different models: a logistic regression,
                an XGBoost model, and a deep neural network (DNN).
              </p>
            )}
          </div>
        </div>

        <h2
          className="model-titre2"
          style={{
            fontSize: "33px",
            
            
          }}
        >
          {lang === 'fr' ? "Performances" : "Performance"}
        </h2>
        {lang === 'fr' ? (
          <p style={{ fontSize: "22px", marginLeft: "20px", color: "#00FFFF" }}>
            La courbe ROC (Receiver Operating Characteristic) ci-dessous est un
            outil d'évaluation permettant de mesurer la performance d'un modèle de
            classification binaire, tel que celui d'Eliza. Cette courbe représente
            la capacité du modèle à discriminer les matchs correctement prédits
            des matchs mal prédits.
            <br />
            <br />
            <img
              src="/images/Figure_1.png"
              alt="Eliza"
              style={{ display: "block", margin: "0 auto", width: "200%" }}
            />
            <br />
          </p>
        ) : (
          <p style={{ fontSize: "22px", marginLeft: "20px", color: "#00FFFF" }}>
            The ROC curve (Receiver Operating Characteristic) below is an
            evaluation tool used to measure the performance of a binary classification model
            like Eliza's. This curve represents the model's ability to discriminate
            correctly predicted matches from incorrectly predicted ones.
            <br />
            <br />
            <img
              src="/images/Figure_1.png"
              alt="Eliza"
              style={{ display: "block", margin: "0 auto", width: "200%" }}
            />
            <br />
          </p>
        )}
        <ul
          style={{
            marginLeft: "40px",
            listStyleType: "disc",
            fontSize: "20px",
            
          }}
        >
          {lang === 'fr' ? (
            <>
              <li>
                L'axe des ordonnées représente le taux de vrais positifs (aussi
                appelé sensibilité) : c'est le pourcentage de matchs gagnés
                correctement classés parmi tous les matchs réellement gagnés.
              </li>
              <br />
              <li>
                L'axe des abscisses représente le taux de faux positifs,
                c'est-à-dire le pourcentage de matchs perdus incorrectement classés
                comme gagnés parmi tous les matchs perdus. Plus la courbe se
                rapproche du coin supérieur gauche du graphique, meilleure est la
                performance du modèle.
              </li>
            </>
          ) : (
            <>
              <li>
                The y-axis represents the true positive rate (also called sensitivity):
                this is the percentage of correctly classified won matches among all
                actually won matches.
              </li>
              <br />
              <li>
                The x-axis represents the false positive rate, i.e., the percentage
                of lost matches incorrectly classified as won among all lost matches.
                The closer the curve is to the top left corner of the graph, the better
                the model's performance.
              </li>
            </>
          )}
        </ul>
        {lang === 'fr' ? (
          <p
            style={{
              fontSize: "22px",
              marginLeft: "20px",
              marginTop: "20px",
              
            }}
          >
            <br />
            Sur notre courbe ROC, l'AUC (Area Under the Curve), ou aire sous la
            courbe, est de 0,76. Ce résultat indique que si vous prenez deux
            matchs, l'un gagné et l'autre perdu, le modèle les classera
            correctement dans 76 % des cas en moyenne. Plus l'AUC est proche de 1,
            meilleure est la performance du modèle. Une AUC de 0,5 indiquerait une
            prédiction aléatoire.
          </p>
        ) : (
          <p
            style={{
              fontSize: "22px",
              marginLeft: "20px",
              marginTop: "20px",
              
            }}
          >
            <br />
            On our ROC curve, the AUC (Area Under the Curve) is 0.76. This result indicates
            that if you take two matches, one won and the other lost, the model will
            correctly classify them in 76% of cases on average. The closer the AUC is to 1,
            the better the model's performance. An AUC of 0.5 would indicate a random prediction.
          </p>
        )}

        <h2
          className="model-titre2"
          style={{
            fontSize: "33px",
            
            
          }}
        >
          {lang === 'fr' ? "Stratégies de paris" : "Betting Strategies"}
        </h2>
        {lang === 'fr' ? (
          <p
            style={{
              fontSize: "22px",
              marginLeft: "20px",
              marginTop: "20px",
              
            }}
          >
            Nous proposons également une stratégie de paris revisitée visant à
            optimiser les gains à long terme tout en minimisant les risques de
            pertes immédiates. Nous avons adapté et révisé la formule de Kelly en
            fonction des paramètres de nos modèles et de leurs résultats.
          </p>
        ) : (
          <p
            style={{
              fontSize: "22px",
              marginLeft: "20px",
              marginTop: "20px",
              
            }}
          >
            We also offer a revised betting strategy aimed at optimizing long-term gains
            while minimizing the risks of immediate losses. We have adapted and revised
            the Kelly formula based on our models' parameters and results.
          </p>
        )}
        <br />
        <div
          style={{
            textAlign: "center",
            fontSize: "1.2em",
            marginTop: "20px",
            
          }}
        >
          <p>{lang === 'fr' ? "Formule de Kelly :" : "Kelly Formula:"}</p>
          {String.raw`\( f^* = \frac{bp - q}{b} \)`}
        </div>
        <ul
          style={{
            marginLeft: "40px",
            listStyleType: "disc",
            fontSize: "20px",
            
          }}
        >
          {lang === 'fr' ? (
            <>
              <li>\( f^* \) : fraction du capital à miser</li>
              <li>\( p \) : probabilité de gagner</li>
              <li>\( q \) : probabilité de perdre</li>
              <li>\( b \) : côte du bookmaker</li>
            </>
          ) : (
            <>
              <li>\( f^* \) : fraction of capital to bet</li>
              <li>\( p \) : probability of winning</li>
              <li>\( q \) : probability of losing</li>
              <li>\( b \) : bookmaker's odds</li>
            </>
          )}
        </ul>

        <div
          className="mt-5"
          style={{ marginLeft: "20px", fontSize: "22px", color: "#00FFFF" }}
        >
          <p
            style={{
              textAlign: "left",
              fontSize: "27px",
              marginBottom: "40px",
            }}
          ></p>

          <div style={{ marginLeft: "20px" }}>
            <h2
              className="model-titre2"
              style={{
                fontSize: "33px",
                
                
              }}
            >
              {lang === 'fr' ? "Capital" : "Capital"}
            </h2>
            {lang === 'fr' ? (
              <p
                style={{
                  fontSize: "22px",
                  marginTop: "20px",
                  marginBottom: "40px",
                  
                }}
              >
                Cela représente la somme d'argent que vous allouez aux paris
                sportifs. Il ne constitue en aucun cas la totalité de vos fonds !
                <br />
                Ne misez que ce que vous pouvez vous permettre de perdre et
                considérez ce capital comme de l'argent déjà dépensé.
              </p>
            ) : (
              <p
                style={{
                  fontSize: "22px",
                  marginTop: "20px",
                  marginBottom: "40px",
                  
                }}
              >
                This represents the amount of money you allocate to sports betting.
                It by no means constitutes your entire funds!
                <br />
                Only bet what you can afford to lose and consider this capital as already spent money.
              </p>
            )}

            <h2
              className="model-titre2"
              style={{
                fontSize: "33px",
                
                
              }}
            >
              {lang === 'fr' ? "Stratégies" : "Strategies"}
            </h2>
            {lang === 'fr' ? (
              <p
                style={{
                  fontSize: "22px",
                  marginTop: "20px",
                  marginBottom: "40px",
                  
                }}
              >
                Nous offrons une stratégie conçue pour accroître le capital
                initial sur le long terme.
                <br />
                Il est recommandé de mettre à jour cette somme mensuellement afin
                de tirer pleinement parti de cette stratégie. Les mises seront
                déterminées par le montant de votre capital. Si vous augmentez ce
                dernier après une journée de gains, les pertes potentielles des
                jours suivants pourraient être plus importantes que prévu
                initialement. En revanche, si vous diminuez le capital après une
                journée de pertes, les gains potentiels seront réduits le jour
                suivant. C'est pourquoi il est important de rester cohérent en ce
                qui concerne votre capital afin d'optimiser les mises.
              </p>
            ) : (
              <p
                style={{
                  fontSize: "22px",
                  marginTop: "20px",
                  marginBottom: "40px",
                  
                }}
              >
                We offer a strategy designed to grow the initial capital over the long term.
                <br />
                It is recommended to update this amount monthly to fully benefit from this strategy.
                The stakes will be determined by the amount of your capital. If you increase this
                after a day of gains, potential losses in the following days could be greater than initially expected.
                Conversely, if you decrease the capital after a day of losses, potential gains will be reduced the following day.
                Therefore, it is important to remain consistent regarding your capital to optimize the bets.
              </p>
            )}

            <h2
              className="model-titre2"
              style={{
                fontSize: "33px",
                
                
              }}
            >
              {lang === 'fr' ? "Côtes" : "Odds"}
            </h2>
            {lang === 'fr' ? (
              <p
                style={{
                  fontSize: "22px",
                  marginTop: "20px",
                  marginBottom: "40px",
                  
                }}
              >
                Après avoir fourni les pronostics, nous proposons une mise
                optimisée en fonction du capital et de la cote du bookmaker
                que vous trouverez sur les sites
                de paris sportifs. Il est recommandé de visiter plusieurs de ces
                sites afin de maximiser vos gains potentiels.
              </p>
            ) : (
              <p
                style={{
                  fontSize: "22px",
                  marginTop: "20px",
                  marginBottom: "40px",
                  
                }}
              >
                After providing the predictions, we offer an optimized stake based on the capital and the bookmaker's odds
                that you will find on sports betting sites. It is recommended to visit several of these sites
                to maximize your potential gains.
              </p>
            )}

            <div className="flex justify-left">
              <Prevention />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
