import React, { useEffect, useState } from "react";
import { Prevention } from "./Prevention";

export const Terms: React.FC = () => {
  const [language, setLanguage] = useState<"fr" | "en">("fr");

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang !== "fr-FR" && userLang !== "fr") {
      setLanguage("en");
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML";
    script.async = true;
    document.head.appendChild(script);
  }, []);

  const translations = {
    fr: {
      title: "Conditions Générales d'Utilisation",
      introduction:
        "Bienvenue sur notre site web Elizabet. En accédant et en utilisant ce site, vous acceptez d'être lié par les présentes conditions générales d'utilisation. Si vous n'acceptez pas ces Conditions, veuillez ne pas utiliser notre site.",
      sections: {
        presentationTitle: "1. Présentation du Site",
        presentationText:
          "Bet on Eliza est une plateforme utilisant une intelligence artificielle pour fournir des pronostics sur les matchs de tennis. Nous proposons également des stratégies de mise basées sur les pronostics, les cotes et le capital de chaque utilisateur. Nous sommes une société autoentrepreneur localisée en France.",
        usageTitle: "2. Utilisation du Site",
        usageText:
          "L'utilisation de notre site est réservée aux personnes âgées de 18 ans et plus. Vous êtes responsable de la confidentialité de vos informations de compte et de toutes les activités effectuées sous votre compte. Toute utilisation frauduleuse ou illicite de notre site entraînera la suspension ou la suppression de votre compte. Conformément à la législation française, il est interdit de parier sous l'influence de l'alcool ou de substances illicites.",
        servicesTitle: "3. Services Proposés",
        servicesText:
          "Nos services incluent des pronostics sur les matchs de tennis générés par une IA, ainsi que des recommandations de mises basées sur la formule de Kelly et autres stratégies avancées. Ces services sont fournis à titre informatif et ne garantissent aucun gain. Il est important de noter que les paris sportifs comportent des risques et peuvent entraîner des pertes financières. Conformément à la législation française, nous rappelons que les jeux d'argent et de hasard sont interdits aux mineurs.",
        responsibilityTitle: "4. Responsabilité",
        responsibilityText:
          "Bet on Eliza ne peut être tenu responsable des pertes financières ou des dommages résultant de l'utilisation de nos pronostics et stratégies de mise. Les paris sportifs comportent des risques et vous ne devez parier que l'argent que vous pouvez vous permettre de perdre. En utilisant nos services, vous reconnaissez comprendre ces risques. Nous déclinons toute responsabilité pour les décisions de paris prises par les utilisateurs sur la base de nos recommandations. En cas de doute, nous vous encourageons à consulter les services d'un conseiller financier.",
        intellectualPropertyTitle: "5. Propriété Intellectuelle",
        intellectualPropertyText:
          "Tout le contenu du site, y compris les textes, graphiques, logos, et logiciels, est la propriété de Bet on Eliza ou de ses fournisseurs de contenu et est protégé par les lois internationales sur le droit d'auteur et les lois françaises relatives à la propriété intellectuelle. Toute reproduction, distribution, modification, ou utilisation de ce contenu sans notre autorisation expresse est strictement interdite.",
        dataProtectionTitle: "6. Protection des Données",
        dataProtectionText:
          "Nous nous engageons à protéger vos données personnelles conformément aux lois en vigueur, notamment le Règlement Général sur la Protection des Données (RGPD). Veuillez consulter notre Politique de Confidentialité pour plus d'informations sur la collecte, l'utilisation et la protection de vos données personnelles.",
        changesTitle: "7. Modification des Conditions",
        changesText:
          "Bet on Eliza se réserve le droit de modifier ces Conditions à tout moment. Nous vous informerons de toute modification en publiant les nouvelles Conditions sur notre site. Les modifications entreront en vigueur dès leur publication. Il vous incombe de consulter régulièrement les Conditions pour vous tenir informé des éventuelles modifications. En continuant à utiliser le site après la publication des modifications, vous acceptez les nouvelles Conditions.",
        applicableLawTitle: "8. Droit Applicable",
        applicableLawText:
          "Les présentes Conditions sont régies par les lois françaises. Tout litige relatif à l'interprétation ou à l'exécution de ces Conditions sera soumis à la compétence exclusive des tribunaux français. En cas de litige, vous pouvez également recourir à un médiateur de la consommation conformément à l'article L. 612-1 du Code de la consommation.",
        contactTitle: "9. Contact",
        contactText:
          "Si vous avez des questions concernant ces Conditions, veuillez nous contacter à l'adresse suivante : contact@elizabet.io.",
      },
    },
    en: {
      title: "Terms and Conditions",
      introduction:
        "Welcome to our Elizabet website. By accessing and using this site, you agree to be bound by these terms and conditions. If you do not agree to these Terms, please do not use our site.",
      sections: {
        presentationTitle: "1. Site Presentation",
        presentationText:
          "Bet on Eliza is a platform using artificial intelligence to provide predictions on tennis matches. We also offer betting strategies based on predictions, odds, and each user's capital. We are a self-employed company located in France.",
        usageTitle: "2. Site Usage",
        usageText:
          "Use of our site is restricted to persons aged 18 and over. You are responsible for maintaining the confidentiality of your account information and all activities conducted under your account. Any fraudulent or unlawful use of our site will result in the suspension or deletion of your account. Under French law, it is prohibited to bet while under the influence of alcohol or illicit substances.",
        servicesTitle: "3. Services Offered",
        servicesText:
          "Our services include AI-generated predictions on tennis matches, as well as betting recommendations based on the Kelly formula and other advanced strategies. These services are provided for informational purposes only and do not guarantee any winnings. It is important to note that sports betting involves risks and can result in financial losses. In accordance with French law, we remind you that gambling is prohibited for minors.",
        responsibilityTitle: "4. Responsibility",
        responsibilityText:
          "Bet on Eliza cannot be held responsible for financial losses or damages resulting from the use of our predictions and betting strategies. Sports betting involves risks, and you should only bet money that you can afford to lose. By using our services, you acknowledge that you understand these risks. We disclaim any responsibility for betting decisions made by users based on our recommendations. If in doubt, we encourage you to consult a financial advisor.",
        intellectualPropertyTitle: "5. Intellectual Property",
        intellectualPropertyText:
          "All content on the site, including text, graphics, logos, and software, is the property of Bet on Eliza or its content suppliers and is protected by international copyright laws and French intellectual property laws. Any reproduction, distribution, modification, or use of this content without our express permission is strictly prohibited.",
        dataProtectionTitle: "6. Data Protection",
        dataProtectionText:
          "We are committed to protecting your personal data in accordance with applicable laws, including the General Data Protection Regulation (GDPR). Please see our Privacy Policy for more information on how we collect, use, and protect your personal data.",
        changesTitle: "7. Changes to Terms",
        changesText:
          "Bet on Eliza reserves the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on our site. Changes will take effect immediately upon posting. It is your responsibility to regularly review the Terms to stay informed of any updates. By continuing to use the site after changes are posted, you agree to the new Terms.",
        applicableLawTitle: "8. Governing Law",
        applicableLawText:
          "These Terms are governed by French law. Any dispute relating to the interpretation or execution of these Terms will be subject to the exclusive jurisdiction of the French courts. In the event of a dispute, you may also use a consumer mediator in accordance with Article L. 612-1 of the French Consumer Code.",
        contactTitle: "9. Contact",
        contactText:
          "If you have any questions about these Terms, please contact us at the following address: contact@elizabet.io.",
      },
    },
  };

  const { title, introduction, sections } = translations[language];

  return (
    <section className="mb-5 mt-10" style={{ margin: "0 20px" }}>
      <div>
        <h1 className="admi-titre1">{title}</h1>
        <p
          style={{
            fontSize: "22px",
            marginLeft: "20px",
            marginTop: "20px",
            

          }}
        >
          {introduction}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px" }}>
          {sections.presentationTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px",  }}>
          {sections.presentationText}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px" }}>
          {sections.usageTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px",  }}>
          {sections.usageText}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px" }}>
          {sections.servicesTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px",  }}>
          {sections.servicesText}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px" }}>
          {sections.responsibilityTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px",  }}>
          {sections.responsibilityText}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px" }}>
          {sections.intellectualPropertyTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px",  }}>
          {sections.intellectualPropertyText}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px" }}>
          {sections.dataProtectionTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px",  }}>
          {sections.dataProtectionText}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px" }}>
          {sections.changesTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px",  }}>
          {sections.changesText}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px" }}>
          {sections.applicableLawTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px",  }}>
          {sections.applicableLawText}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px" }}>
          {sections.contactTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px",  }}>
          {sections.contactText}
        </p>

        <div className="flex justify-left" style={{ marginTop: "80px" }}>
          <Prevention />
        </div>
      </div>
    </section>
  );
};
