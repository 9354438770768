import React from "react";
import axios from "axios";
import cookie from "js-cookie";

const Logout = () => {
  const removeCookie = (key: string) => {
    if (window !== undefined) {
      cookie.remove(key, { expires: 1 }); // Supprime le cookie immédiatement
    }
  };

  const handleLogout = async () => {
    try {
      await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}api/user/logout`,
        withCredentials: true,
      }).then(() => removeCookie("jwt"));
 
      window.location.href = "/";
    } catch (err) {
 
      window.location.href = "/";
    }
    /* window.location.href = "/";  */
  };

  return (
    <button
     className="custom-link"
      onClick={handleLogout}
   

    >
      Se déconnecter 
    </button>
  );
};

export default Logout;
