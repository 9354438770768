import React, { useEffect, useState, useContext } from "react";
import Log from "../components/Log/index";
import { UidContext } from "../components/AppContext";
import { useMediaQuery } from "react-responsive";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const stripePromise = loadStripe(
  "pk_live_51PfI5fRtpmJcb5L9auPAtjw6szSEUL4lrMKywJzsh2NTNnjjAZDyhV5Rv9lryY7OkIbxnf2ad6Z7hdrHzKMP92C400U20a5CSG"
);

const currentDate = new Date();
const day = currentDate.getDate();
const month = currentDate.getMonth() + 1;
const formattedCurrentDate = `${day < 10 ? "0" + day : day}-${
  month < 10 ? "0" + month : month
}`;

const currentDate1 = new Date();
currentDate1.setDate(currentDate1.getDate() + 1);
const day1 = currentDate1.getDate();
const month1 = currentDate1.getMonth() + 1;
const formattedNextDate = `${day1 < 10 ? "0" + day1 : day1}-${
  month1 < 10 ? "0" + month1 : month1
}`;

const currentDate2 = new Date();
currentDate2.setDate(currentDate2.getDate() + 2);
const day2 = currentDate2.getDate();
const month2 = currentDate2.getMonth() + 1;
const formattedNextNextDate = `${day2 < 10 ? "0" + day2 : day2}-${
  month2 < 10 ? "0" + month2 : month2
}`;
const randomizePlayerNames = (
  winner: string,
  loser: string,
  worthit: boolean,
  lang: string
): React.ReactNode => {
  const phrases = [
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr auto 1fr",
        alignItems: "center",
        justifyItems: "center",
      }}
    >
      <span style={{ textShadow: "0 0 8px #00d8ff, 0 0 15px #00d8ff" }}>
        {winner}
      </span>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <i
          style={{
            color: "#FFFF00",
            fontSize: "33px",
            marginLeft: "20px",
            marginRight: "20px",
            textShadow: "0 0 8px #00d8ff, 0 0 15px #00d8ff",
          }}
        >
          vs
        </i>
        {worthit && (
          <img
            src="/images//logo3.png"
            alt="ROC Indicator"
            style={{
              width: "30px",
              height: "30px",
              marginTop: "20px",
              marginBottom: "-50px",
            }}
          />
        )}
      </div>
      <span style={{ textShadow: "0 0 8px #00d8ff, 0 0 15px #00d8ff" }}>
        {loser}
      </span>
    </div>,
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr auto 1fr",
        alignItems: "center",
        justifyItems: "center",
      }}
    >
      <span style={{ textShadow: "0 0 8px #00d8ff, 0 0 15px #00d8ff" }}>
        {loser}
      </span>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <i
          style={{
            color: "#FFFF00",
            fontSize: "33px",
            marginLeft: "20px",
            marginRight: "20px",
            textShadow: "0 0 8px #00d8ff, 0 0 15px #00d8ff",
          }}
        >
          vs
        </i>
        {worthit && (
          <img
            src="/images//logo3.png"
            alt="ROC Indicator"
            style={{
              width: "30px",
              height: "30px",
              marginTop: "20px",
              marginBottom: "-50px",
            }}
          />
        )}
      </div>
      <span style={{ textShadow: "0 0 8px #00d8ff, 0 0 15px #00d8ff" }}>
        {winner}
      </span>
    </div>,
  ];
  const randomIndex = Math.floor(Math.random() * phrases.length);
  return phrases[randomIndex];
};

const randomizeWinningPhrase = (
  winner: string,
  probability: number,
  lang: string
) => {
  const phrases =
    lang === "fr"
      ? [
          `${winner} l'emporte avec une probabilité de ${probability.toFixed(
            0
          )}%`,
          `${winner} est victorieux à ${probability.toFixed(0)}%`,
          `La victoire est pronostiquée pour ${winner} avec une probabilité de ${probability.toFixed(
            0
          )}%`,
          `Les probabilités de victoire pour ${winner} sont de ${probability.toFixed(
            0
          )}%`,
          `${probability.toFixed(0)}% de chances que ${winner} l'emporte.`,
        ]
      : [
          `${winner} wins with a probability of ${probability.toFixed(0)}%`,
          `${winner} is victorious with ${probability.toFixed(0)}% probability`,
          `Victory is predicted for ${winner} with a probability of ${probability.toFixed(
            0
          )}%`,
          `The winning probabilities for ${winner} are ${probability.toFixed(
            0
          )}%`,
          `${probability.toFixed(0)}% chance that ${winner} will win.`,
        ];
  const randomIndex = Math.floor(Math.random() * phrases.length);
  return phrases[randomIndex];
};

const randomizeWorthPhrase = (
  worthit: boolean,
  lang: string
): React.ReactNode => {
  let phrases: string[];

  if (worthit === false) {
    phrases =
      lang === "fr"
        ? [
            `Il n'est pas rentable de miser sur ce match`,
            `Ce pari n'est pas rentable à long terme`,
            `Il est préférable de ne pas parier sur ce match`,
          ]
        : [
            `It is not profitable to bet on this match`,
            `This bet is not profitable in the long term`,
            `It is better not to bet on this match`,
          ];
  } else {
    phrases =
      lang === "fr"
        ? [
            `Ce pari est rentable !!`,
            `Ce pari vaut le coup !`,
            `Il est rentable de miser sur ce pari à long terme !`,
            `Pour maximiser vos gains à long terme, misez sur ce match !`,
          ]
        : [
            `This bet is profitable !!`,
            `This bet is worth it!`,
            `It is profitable to bet on this bet in the long term!`,
            `To maximize your long-term gains, bet on this match!`,
          ];
  }

  const randomIndex = Math.floor(Math.random() * phrases.length);
  return phrases[randomIndex];
};

const RandomizeKellyShrink = (
  cote: number,
  worthit: boolean,
  proba: number,
  bankroll: number,
  risk: number,
  lang: string
): [string, string] => {
  const p = proba / 100;
  const q = 1 - p;
  const kellyfraction = (cote * p - q) / (cote ** 2 * 0.293923583);
  const bet_size = kellyfraction * risk;
  const bet = bankroll * bet_size;
  const probo = 100 - proba;
  let worth = bet * cote * proba - bet * proba - bet * probo;

  let phrases: string[];
  let randomIndex: number;
  let phrases2: string[];
  let randomIndex2: number;

  phrases2 = [
    `${lang === "fr" ? "Mise conseillée" : "Recommended bet"} : ${bet.toFixed(
      2
    )}€`,
  ];

  randomIndex2 = Math.floor(Math.random() * phrases2.length);

  if (worth < 0) {
    phrases =
      lang === "fr"
        ? [
            `Ce pari ne présente pas de rentabilité à long terme, l'on finit par perdre ${(
              worth * -1
            ).toFixed()}€ sur cent matchs similaires`,

            `Il n'est pas rentable de parier sur ce match, l'on finit par perdre ${(
              worth * -1
            ).toFixed()}€ sur cent matchs similaires`,

            `Ce n'est pas rentable de parier sur ce match à long terme, l'on finit par perdre ${(
              worth * -1
            ).toFixed()}€ sur cent matchs similaires`,

            `Parier sur ce match à long terme n'est pas rentable, l'on finit par perdre ${(
              worth * -1
            ).toFixed()}€ sur cent matchs similaires`,
          ]
        : [
            `This bet does not present long-term profitability, we end up losing ${(
              worth * -1
            ).toFixed()}€ over a hundred similar matches`,

            `It is not profitable to bet on this match, we end up losing ${(
              worth * -1
            ).toFixed()}€ over a hundred similar matches`,

            `It is not profitable to bet on this match in the long term, we end up losing ${(
              worth * -1
            ).toFixed()}€ over a hundred similar matches`,

            `Betting on this match in the long term is not profitable, we end up losing ${(
              worth * -1
            ).toFixed()}€ over a hundred similar matches`,
          ];
    randomIndex = Math.floor(Math.random() * phrases.length);
  } else {
    return ["", phrases2[randomIndex2]];
  }

  return [phrases[randomIndex], phrases2[randomIndex2]];
};

const randomizeWorthbet = (worthit: boolean, lang: string): React.ReactNode => {
  let phrases: string[];

  if (worthit === false) {
    phrases = [lang === "fr" ? `Miser quand même` : `Bet anyway`];
  } else {
    phrases = [lang === "fr" ? `Miser sur ce pari` : `Bet on this bet`];
  }
  const randomIndex = Math.floor(Math.random() * phrases.length);
  return phrases[randomIndex];
};

function formatDateString(dateString: string, lang: string) {
  const [day, month] = dateString.split("-");
  const monthNames =
    lang === "fr"
      ? [
          "janvier",
          "février",
          "mars",
          "avril",
          "mai",
          "juin",
          "juillet",
          "août",
          "septembre",
          "octobre",
          "novembre",
          "décembre",
        ]
      : [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
  const monthName = monthNames[parseInt(month, 10) - 1];
  return `${day} ${monthName} 2024`;
}

interface Match {
  id: string;
  date: string;
  tournoi: string;
  round: string;
  surface: number;
  voting: {
    vainqueur: string;
    perdant: string;
    proba: number;
    roc: boolean;
    worthit: boolean;
    shrink: { mise: number; gain: number; bet_size: number };
    clemz: { mise: number; gain: number };
    shrink_ROC: {
      mise: number;
      gain: number;
      bet_size: number;
      interest: number;
    };
  };
  dnn: {
    vainqueur: string;
    perdant: string;
    proba: number;
    roc: boolean;
    worthit: boolean;
    shrink: { mise: number; gain: number; bet_size: number };
    clemz: { mise: number; gain: number };
    shrink_ROC: {
      mise: number;
      gain: number;
      bet_size: number;
      interest: number;
    };
  };
  xgbc: {
    vainqueur: string;
    perdant: string;
    proba: number;
    roc: boolean;
    worthit: boolean;
    shrink: { mise: number; gain: number; bet_size: number };
    clemz: { mise: number; gain: number };
    shrink_ROC: {
      mise: number;
      gain: number;
      bet_size: number;
      interest: number;
    };
  };
}

export const Bet: React.FC = () => {
  const [lang, setLang] = useState("fr");
  useEffect(() => {
    const userLang = navigator.language;
    if (userLang !== "fr-FR" && userLang !== "fr") {
      setLang("en");
    }
  }, []);

  const navigate = useNavigate();

  const handleCheckout = async () => {
    const stripe = await stripePromise;

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}api/user/create-checkout-session`,
      withCredentials: true,
    })
      .then(async (res) => {
        const session = res.data;
        const result = await stripe?.redirectToCheckout({
          sessionId: session.id,
        });
        if (result?.error) {
          alert(result.error.message);
        }
      })
      .catch((err) => {
        alert(
          `${
            lang === "fr"
              ? "Erreur lors de la requête"
              : "Error during the request"
          }: ${err}`
        );
      });
  };

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const uid = useContext(UidContext);
  const uidControl = uid?.uid;
  const pseudo = uid?.pseudo;
  const abo = uid?.Abo;

  const [matches, setMatches] = useState<{ date: string; matches: Match[] }[]>(
    []
  );
  const dates = [
    formattedCurrentDate,
    formattedNextDate,
    formattedNextNextDate,
  ];

  const [resultShownBets, setResultShownBets] = useState<string[]>([]);
  const [resultBetPhrases, setResultBetPhrases] = useState<{
    [key: string]: string | React.ReactNode;
  }>({});
  const [resultBetPhrases2, setResultBetPhrases2] = useState<{
    [key: string]: string | React.ReactNode;
  }>({});

  const [resultShownMatches, setResultShownMatches] = useState<string[]>([]);
  const [resultPhrases, setResultPhrases] = useState<{ [key: string]: string }>(
    {}
  );

  const [showMiserQuandMemeButton, setShowMiserQuandMemeButton] = useState<{
    [key: string]: boolean;
  }>({});

  const [playerNames, setPlayerNames] = useState<{
    [key: string]: React.ReactNode;
  }>({});
  const [betphrases, setBetPhrases] = useState<{
    [key: string]: React.ReactNode;
  }>({});
  const [showResultBetPhrase2, setShowResultBetPhrase2] = useState<{
    [key: string]: boolean;
  }>({});

  const [miserClickedMatches, setMiserClickedMatches] = useState<string[]>([]);
  const [worthBetphrases, setWorthBetPhrases] = useState<{
    [key: string]: React.ReactNode;
  }>({});
  const [betAmounts, setBetAmounts] = useState<{ [key: string]: number }>({});
  const [showBetInput, setShowBetInput] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [bankroll, setBankroll] = useState<number | null>(null);
  const [riskLevel, setRiskLevel] = useState<number>(1 / 50);
  const [forcedBets, setForcedBets] = useState<{
    [key: string]: React.ReactNode;
  }>({});
  const [nb_worthit, setNbRoc] = useState(0);

  const [odds, setOdds] = useState<{ [key: string]: number }>({});
  const [emptyData, setEmptyData] = useState<null | boolean>(null);

  useEffect(() => {
    const fetchData = async (date: string) => {
      try {
        const response = await fetch(`/matchs/${date}.json`);
        const data = await response.json();
        // Mise à jour de l'état emptyData
        const isEmptyData = data.matche && data.matche.length > 0 ? true : null;
        setEmptyData(isEmptyData); // Utiliser useState pour stocker la valeur de empty_data
        console.log("test data: ", isEmptyData);

        setMatches((prevMatches) =>
          [
            ...prevMatches.filter((matchGroup) => matchGroup.date !== date),
            { date, matches: data.matche },
          ].sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0))
        );
      } catch (error) {}
    };

    dates.forEach((date) => {
      fetchData(date);
    });
  }, [lang]);

  useEffect(() => {
    const handleBetphrases = (match: Match) => {
      if (!betphrases[match.id]) {
        const betphrase = randomizeWorthPhrase(match.voting.worthit, lang);
        setBetPhrases((prevState) => ({ ...prevState, [match.id]: betphrase }));
      }
    };

    const handleWorthBetphrases = (match: Match) => {
      if (!worthBetphrases[match.id]) {
        const worthBetphrase = randomizeWorthbet(match.voting.worthit, lang);
        setWorthBetPhrases((prevState) => ({
          ...prevState,
          [match.id]: worthBetphrase,
        }));
      }
    };

    const handlePlayerNames = (match: Match) => {
      if (!playerNames[match.id]) {
        const playerName = randomizePlayerNames(
          match.voting.vainqueur,
          match.voting.perdant,
          match.voting.worthit,
          lang
        );
        setPlayerNames((prevState) => ({
          ...prevState,
          [match.id]: playerName,
        }));
      }
    };

    matches.forEach((matchGroup) => {
      matchGroup.matches.forEach((match) => {
        handlePlayerNames(match);
        handleBetphrases(match);
        handleWorthBetphrases(match);
      });
    });
  }, [
    matches,
    setPlayerNames,
    playerNames,
    setBetPhrases,
    betphrases,
    setWorthBetPhrases,
    worthBetphrases,
    lang,
  ]);

  const handleShowBetInput = (matchId: string) => {
    setShowBetInput((prevState) => ({ ...prevState, [matchId]: true }));
  };

  const handleShowResult = (match: Match) => {
    if (bankroll === null) {
      let userBankroll;
      do {
        userBankroll = prompt(
          lang === "fr"
            ? "Veuillez entrer votre capital dédié aux paris sportifs"
            : "Please enter your sports betting bankroll"
        );
        if (userBankroll === null) {
          alert(
            lang === "fr"
              ? "Vous devez saisir votre capital dédié aux paris sportifs pour déterminer la mise optimale"
              : "You must enter your sports betting bankroll to determine the optimal bet"
          );
          return;
        }
        if (!/^\d+(\.\d+)?$/.test(userBankroll)) {
          alert(
            lang === "fr"
              ? "Veuillez entrer un nombre entier valide."
              : "Please enter a valid integer."
          );
        }
      } while (!/^\d+(\.\d+)?$/.test(userBankroll));

      setBankroll(parseFloat(userBankroll));
    }

    setResultShownMatches((prevState) => [...prevState, match.id]);
    if (!resultPhrases[match.id]) {
      const resultPhrase = randomizeWinningPhrase(
        match.voting.vainqueur,
        match.voting.proba,
        lang
      );
      setResultPhrases((prevState) => ({
        ...prevState,
        [match.id]: resultPhrase,
      }));
    }
    setShowBetInput((prevState) => ({ ...prevState, [match.id]: true }));
  };

  const handleWorthBetClick = (match: Match) => {
    if (!showBetInput[match.id]) {
      handleShowBetInput(match.id);
    } else {
      if (!bankroll) {
        const userBankroll = prompt(
          lang === "fr"
            ? "Veuillez saisir le montant de votre capital ici"
            : "Please enter your bankroll amount here"
        );
        if (userBankroll !== null) {
          setBankroll(parseFloat(userBankroll));
        } else {
          alert(
            lang === "fr"
              ? "Vous devez entrer le montant de votre capital dédié aux paris sportifs."
              : "You must enter the amount of your sports betting bankroll."
          );
          return;
        }
      }

      if (bankroll !== null) {
        handlePlaceBet(match);
      }
    }
  };

  const handleBetAmountChange = (matchId: string, amount: number) => {
    setBetAmounts((prevState) => ({ ...prevState, [matchId]: amount }));
  };

  const handleBankrollSubmit = () => {
    if (betAmounts["user_bet"]) {
      setBankroll(betAmounts["user_bet"]);
      setShowBetInput((prevState) => ({ ...prevState, user_bet: false }));
    }
  };

  const handlePlaceBet = (match: Match) => {
    setResultShownBets((prevState) => [...prevState, match.id]);
    let coteInput = odds[match.id]?.toString() || "";

    if (
      coteInput === "" ||
      !/^\d+(\.\d+)?$/.test(coteInput) ||
      parseFloat(coteInput) <= 1
    ) {
      alert(
        lang === "fr"
          ? `Veuillez saisir la côte donnant ${match.voting.vainqueur} vainqueur svp`
          : `Please enter the odds giving ${match.voting.vainqueur} as the winner`
      );

      let newCoteInput = prompt(
        lang === "fr"
          ? `Côte donnée pour ${match.voting.vainqueur}`
          : `Odds given for ${match.voting.vainqueur}`
      );

      while (
        newCoteInput === null ||
        newCoteInput === "" ||
        !/^\d+(\.\d+)?$/.test(newCoteInput) ||
        parseFloat(newCoteInput) <= 1
      ) {
        alert(
          lang === "fr"
            ? "Cote invalide ou saisie annulée. Veuillez utiliser un point à la place de la virgule"
            : "Invalid or cancelled odds. Please use a dot instead of a comma"
        );
        newCoteInput = prompt(
          lang === "fr"
            ? `Côte donnée pour ${match.voting.vainqueur}`
            : `Odds given for ${match.voting.vainqueur}`
        );
      }

      coteInput = newCoteInput;
    }

    const cote = parseFloat(coteInput);

    let bankrollInput = bankroll?.toString() || "";

    if (bankrollInput === "" || !/^\d+(\.\d+)?$/.test(bankrollInput)) {
      const userInput = prompt(
        lang === "fr"
          ? "Veuillez entrer votre bankroll"
          : "Please enter your bankroll"
      );
      if (userInput === null || !/^\d+(\.\d+)?$/.test(userInput)) {
        alert(
          lang === "fr"
            ? "Vous devez saisir un nombre valide."
            : "You must enter a valid number."
        );
        return;
      }
      bankrollInput = userInput;
      setBankroll(parseFloat(bankrollInput));
    }

    const userBankroll = parseFloat(bankrollInput);

    if (userBankroll && cote !== undefined) {
      const [resultBetPhrase] = RandomizeKellyShrink(
        cote,
        match.voting.worthit,
        match.voting.proba,
        userBankroll,
        riskLevel,
        lang
      );
      setResultBetPhrases((prevState) => ({
        ...prevState,
        [match.id]: resultBetPhrase,
      }));

      const [, resultBetPhrase2] = RandomizeKellyShrink(
        cote,
        match.voting.worthit,
        match.voting.proba,
        userBankroll,
        riskLevel,
        lang
      );

      setResultBetPhrases2((prevState) => ({
        ...prevState,
        [match.id]: resultBetPhrase2,
      }));
    }
  };

  const handleMiserClick = (matchId: string) => {
    setShowMiserQuandMemeButton((prevState) => ({
      ...prevState,
      [matchId]: true,
    }));
  };

  const handleMiserQuandMemeClick = (matchId: string) => {
    setShowResultBetPhrase2((prevState) => ({
      ...prevState,
      [matchId]: true,
    }));
    setShowMiserQuandMemeButton((prevState) => ({
      ...prevState,
      [matchId]: true,
    }));
    setMiserClickedMatches((prevMatches) => [...prevMatches, matchId]);
  };

  useEffect(() => {
    const countRocMatches = () => {
      let count = 0;
      matches.forEach((matchGroup) => {
        matchGroup.matches.forEach((match) => {
          if (match.voting.worthit) {
            count++;
          }
        });
      });
      setNbRoc(count);
    };

    countRocMatches();
  }, [matches]);

  return (
    <>
      {isMobile ? (
        <section
          className="mb-20 mt-20"
          style={{ width: "100%", boxSizing: "border-box" }}
        >
          <div className={uidControl ? "profil-page-bis" : "profil-page"}
                 style={{ width: "100%", boxSizing: "border-box" }}
                 >
            <div
              className="log-container"
              style={{ width: "100%", boxSizing: "border-box" }}
            >
              {uidControl ? (
                <div
                  className="connection-form"
                  style={{
                    fontSize: "18px",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <p className="capital-desc">
                    <strong style={{ fontSize: "1.3em" }}>
                      {lang === "fr" ? "Bonjour" : "Hello"} {pseudo} !
                    </strong>
                  </p>
                  <br />

                  {bankroll !== null ? (
                    <div className="capital-section">
                      <p style={{ lineHeight: "2" }}>
                        {lang === "fr"
                          ? "Vous disposez d'un capital de"
                          : "You have a bankroll of"}{" "}
                        <strong>{bankroll}€ </strong>
                        <br />
                        {nb_worthit > 1 ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "30px",
                            }}
                          >
                            <img
                              src="/images//logo3.png"
                              alt="ROC Indicator"
                              style={{
                                width: "80px",
                                height: "80px",
                                marginRight: "5px",
                                marginLeft: "20px",
                              }}
                            />
                            <span>
                              {lang === "fr" ? "Il y a" : "There are"}{" "}
                              <strong>
                                {nb_worthit}{" "}
                                {lang === "fr"
                                  ? "matches intéressants aujourd'hui"
                                  : "interesting matches today"}
                              </strong>
                            </span>
                          </div>
                        ) : (
                          <p>
                            {lang === "fr" ? "Il y a" : "There is"}{" "}
                            <strong>
                              {nb_worthit}{" "}
                              {lang === "fr"
                                ? "matche intéressant aujourd'hui"
                                : "interesting match today"}
                            </strong>{" "}
                          </p>
                        )}
                        {lang === "fr"
                          ? "Veuillez indiquer la fraction de votre capital que vous voulez allouer aux paris sportifs pour chaque match"
                          : "Please indicate the fraction of your bankroll that you want to allocate to sports betting for each match"}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="capital-desc">
                        {lang === "fr"
                          ? "Saisissez votre capital disponible"
                          : "Enter your available bankroll"}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="number"
                          value={betAmounts["user_bet"] || ""}
                          onChange={(e) =>
                            handleBetAmountChange(
                              "user_bet",
                              parseInt(e.target.value)
                            )
                          }
                          className="bet-input"
                          style={{
                            borderRadius: "20px",
                            padding: "10px 15px",
                            fontSize: "25px",
                            color: "#00FFFF",
                            border: "1px solid #ccc",
                            width: "50%",
                            boxSizing: "border-box",
                            marginBottom: "20px",
                            fontWeight: "bold",
                            marginTop: "20px",
                          }}
                        />
                        <button
                          onClick={handleBankrollSubmit}
                          style={{
                            backgroundColor: "#001f3f",
                            color: "#FFD700",
                            padding: "10px 20px",
                            textDecoration: "none",
                            fontWeight: "bold",
                            fontSize: "18px",

                            cursor: "pointer",
                            width: "50%",
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = "#FFEB3B";
                            e.currentTarget.style.color = "#001f3f";
                            e.currentTarget.style.boxShadow =
                              "0 0 15px #FFEB3B, 0 0 30px #FFEB3B, 0 0 45px #FFEB3B"; // Effet néon jaune
                            e.currentTarget.style.border = "none";
                            e.currentTarget.style.textShadow =
                              "0 0 8px #00d8ff, 0 0 15px #00d8ff";
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = "#001f3f";
                            e.currentTarget.style.color = "#FFEB3B";
                            e.currentTarget.style.boxShadow = "none";
                            e.currentTarget.style.textShadow = "none";
                          }}
                        >
                          {lang === "fr" ? "Valider" : "Validate"}
                        </button>
                      </div>
                    </div>
                  )}
                  <br />
                  {bankroll !== null ? (
                    <div className="risk-section">
                      <p className="risk-label">
                        {lang === "fr" ? "Fraction à miser" : "Fraction to bet"}
                      </p>
                      <br />
                      <div className="risk-buttons">
                        <button
                          className={`risk-button ${
                            riskLevel === 1 / 50 ? "active" : ""
                          }`}
                          onClick={() => setRiskLevel(1 / 50)}
                        >
                          <p style={{ fontSize: "1.3em" }}>
                            {lang === "fr" ? "Faible" : "Low"}
                          </p>
                          <p style={{ fontSize: "0.9em" }}>(1/50)</p>
                        </button>
                        <button
                          className={`risk-button ${
                            riskLevel === 1 / 40 ? "active" : ""
                          }`}
                          onClick={() => setRiskLevel(1 / 40)}
                        >
                          <p style={{ fontSize: "1.3em" }}>
                            {lang === "fr" ? "Moyen" : "Medium"}
                          </p>
                          <p style={{ fontSize: "0.9em" }}>(1/40)</p>
                        </button>
                        <button
                          className={`risk-button ${
                            riskLevel === 1 / 30 ? "active" : ""
                          }`}
                          onClick={() => setRiskLevel(1 / 30)}
                        >
                          <p style={{ fontSize: "1.3em" }}>
                            {lang === "fr" ? "Élevé" : "High"}
                          </p>
                          <p style={{ fontSize: "0.9em" }}>(1/30)</p>
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <Log signup={false} signin={true} />
              )}

              <div
                className="img-container"
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <img
                  src={
                    uidControl
                      ? "./images/eliza_decolt.png"
                      : "./images/eliza_rose.png"
                  }
                  alt="img-log"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    boxShadow:
                      "0 0 15px #FFEB3B, 0 0 30px #FFEB3B, 0 0 45px #FFEB3B",
                    objectFit: "contain",
                    borderRadius: "10px",
                    marginLeft: "0",
                    
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ fontSize: "26px", color: "black", marginTop: "20px" }}>
            <p>
              "The question isn't who's going to let me; it's who's going to
              stop me." — Ayn Rand
            </p>
          </div>
          {bankroll !== null ? (
            <p
              className="mt-12px"
              style={{
                fontSize: "22px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "5px 10px",
                textAlign: "center",
                marginBottom: "0px",
                fontWeight: "bold",
              }}
            >
              {lang === "fr"
                ? `Une fois le pronostic établi, Eliza vous demandera d'indiquer la cote attribuée par les bookmakers au gagnant. Cela lui permettra de déterminer si un pari sur ce match est judicieux et, le cas échéant, de calculer le montant optimal à miser. Cette évaluation se base sur les probabilités fournies par Eliza, votre capital disponible, la fraction de mise souhaitée, ainsi que la cote indiquée. Les paris rentables à long terme seront signalés par son logo.`
                : `Once the prediction is made, Eliza will ask you to indicate the odds assigned by the bookmakers to the winner. This will allow her to determine if a bet on this match is worthwhile and, if so, to calculate the optimal amount to bet. This evaluation is based on the probabilities provided by Eliza, your available bankroll, the desired bet fraction, and the indicated odds. Long-term profitable bets will be indicated by her logo.`}
            </p>
          ) : null}

          {matches.map((matchGroup, index) => (
            <div
              key={index}
              className="mb-20"
              style={{ width: "100%", boxSizing: "border-box" }}
            >
              <p
                className="mt-20 mb-0"
                style={{ fontWeight: "bold", fontSize: "30px" }}
              >
                {formatDateString(matchGroup.date, lang)}
              </p>
              <br />
              {Object.entries(
                matchGroup.matches.reduce((acc, match) => {
                  const key = `${match.tournoi}-${match.round}`;
                  if (!acc[key]) {
                    acc[key] = [];
                  }
                  acc[key].push(match);
                  return acc;
                }, {} as { [key: string]: Match[] })
              ).map(([key, matches]) => (
                <div
                  key={key}
                  style={{
                    marginLeft: "0",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <p
                    className="mb-20 mt-20"
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                  >
                    {matches[0].tournoi} - {matches[0].round}
                  </p>
                  <ul>
                    {matches.map((match, matchIndex) => (
                      <li
                        key={matchIndex}
                        style={{
                          position: "relative",
                          backgroundColor: "#001f3f",
                          color: "white",
                          borderRadius: "20px",
                          padding: "10px 20px",
                          margin: "100px 0",
                          maxWidth: "100%",
                          overflow: "visible",
                          boxSizing: "border-box",
                        }}
                      >
                        <span
                          style={{
                            position: "absolute",
                            top: "0",
                            bottom: "0",
                            left: "10%",
                            width: "0",
                            borderLeft: "2px dashed white",
                            transform: "translateX(50%)",
                          }}
                        ></span>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "0",
                            width: "30px",
                            height: "100px",
                            backgroundColor: "black",
                            borderRadius: "0 120px 120px 0",
                            transform: "translateY(-50%)",
                          }}
                        ></div>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "0",
                            width: "30px",
                            height: "100px",
                            backgroundColor: "black",
                            borderRadius: "120px 0 0 120px",
                            transform: "translateY(-50%)",
                          }}
                        ></div>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "50px",
                            fontSize: "19px",
                            fontWeight: "bold",
                            padding: "30px",
                            boxSizing: "border-box",
                          }}
                        >
                          {playerNames[match.id]}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "20px",
                            fontSize: "18px",
                            lineHeight: "2",
                            boxSizing: "border-box",
                          }}
                        >
                          {resultShownMatches.includes(match.id) ? (
                            <>
                              <p
                                style={{
                                  padding: "30px",
                                  marginBottom: "-40px",
                                  color: "#00FFFF",
                                }}
                              >
                                {resultPhrases[match.id]}
                              </p>
                              <br />
                              {!resultShownBets.includes(match.id) && (
                                <>
                                  <input
                                    type="number"
                                    value={odds[match.id] || ""}
                                    onChange={(e) =>
                                      setOdds({
                                        ...odds,
                                        [match.id]: parseFloat(e.target.value),
                                      })
                                    }
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        handlePlaceBet(match);
                                      }
                                    }}
                                    placeholder={
                                      lang === "fr"
                                        ? "Côte du book"
                                        : "Book odds"
                                    }
                                    style={{
                                      color: "#00FFFF",
                                      width: "70%",
                                      borderColor: "#FFEB3B",
                                      textAlign: "center",
                                      boxSizing: "border-box",
                                      fontFamily: "Orbitron",
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                    }}
                                    size={8}
                                  />
                                  <br />

                                  <button
                                    className="button3"
                                    style={{ width: "150px" }}
                                    onClick={() => handlePlaceBet(match)}
                                  >
                                    <p>
                                      {lang === "fr" ? "Voir mise" : "View bet"}
                                    </p>
                                  </button>
                                </>
                              )}
                              {resultBetPhrases[match.id] !== undefined &&
                              resultBetPhrases[match.id] !== "" ? (
                                <div>
                                  <p
                                    style={{
                                      padding: "30px",
                                      color: "#00FFFF",
                                    }}
                                  >
                                    {resultBetPhrases[match.id]}
                                  </p>

                                  {!showMiserQuandMemeButton[match.id] ? (
                                    <>
                                      <br />
                                      <button
                                        className="button3"
                                        style={{ width: "150px" }}
                                        onClick={() =>
                                          handleMiserQuandMemeClick(match.id)
                                        }
                                      >
                                        {lang === "fr"
                                          ? "Miser quand même"
                                          : "Bet anyway"}
                                      </button>
                                    </>
                                  ) : null}
                                  {showResultBetPhrase2[match.id] && (
                                    <p style={{ color: "#00FFFF" }}>
                                      {resultBetPhrases2[match.id]}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <p style={{ color: "#00FFFF" }}>
                                  {resultBetPhrases2[match.id]}
                                </p>
                              )}
                            </>
                          ) : (
                            <button
                              className="button3"
                              style={{ width: "200px" }}
                              onClick={async () => {
                                if (!uidControl) {
                                  const confirmation = window.confirm(
                                    lang === "fr"
                                      ? "Vous devez être connecté pour accéder aux pronostiques"
                                      : "You must be logged in to access the predictions"
                                  );
                                  if (confirmation) {
                                    window.location.href = "/connexion";
                                    alert(
                                      lang === "fr"
                                        ? "Redirection vers la page de connexion ou d'inscription"
                                        : "Redirecting to the login or sign-up page"
                                    );
                                  }
                                } else {
                                  if (match.voting.worthit) {
                                    if (abo) {
                                      handleShowResult(match);
                                    } else {
                                      const confirmation = window.confirm(
                                        lang === "fr"
                                          ? "Vous devez être membre premium pour accéder à ce pronostique"
                                          : "You must be a premium member to access this prediction"
                                      );
                                      if (confirmation) {
                                        try {
                                          const stripe = await stripePromise;

                                          const { data: session } =
                                            await axios.post(
                                              `${process.env.REACT_APP_API_URL}api/user/create-checkout-session`,
                                              {},
                                              { withCredentials: true }
                                            );

                                          const result =
                                            await stripe?.redirectToCheckout({
                                              sessionId: session.id,
                                            });

                                          if (result?.error) {
                                          }
                                        } catch (error) {}
                                      }
                                    }
                                  } else {
                                    handleShowResult(match);
                                  }
                                }
                              }}
                            >
                              <p>
                                {lang === "fr"
                                  ? "Voir résultat"
                                  : "View result"}
                              </p>
                            </button>
                          )}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
        </section>
      ) : (
        <section className="mb-20 mt-20">
          <div className={uidControl ? "profil-page-bis" : "profil-page"}>
            <div className="log-container">
              {uidControl ? (
                <div className="connection-form" style={{ fontSize: "23px" }}>
                  <p className="capital-desc">
                    <strong style={{ fontSize: "1.3em" }}>
                      {lang === "fr" ? "Bonjour" : "Hello"} {pseudo} !
                    </strong>
                  </p>
                  <br />
                  {bankroll !== null ? (
                    <div className="capital-section">
                      <p style={{ lineHeight: "2" }}>
                        {lang === "fr"
                          ? "Vous disposez d'un capital de"
                          : "You have a bankroll of"}{" "}
                        <strong>{bankroll}€ </strong>
                        <br />
                        {nb_worthit > 1 ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "30px",
                            }}
                          >
                            <img
                              className="className"
                              src="/images//logo3.png"
                              alt="ROC Indicator"
                              style={{
                                width: "80px",
                                height: "80px",
                                marginRight: "5px",
                                marginLeft: "50px",
                              }}
                            />
                            <span>
                              {lang === "fr" ? "Il y a" : "There are"}{" "}
                              <strong>
                                {nb_worthit}{" "}
                                {lang === "fr"
                                  ? "matches intéressants aujourd'hui"
                                  : "interesting matches today"}
                              </strong>
                            </span>
                          </div>
                        ) : (
                          <p>
                            {lang === "fr" ? "Il y a" : "There is"}{" "}
                            <strong>
                              {nb_worthit}{" "}
                              {lang === "fr"
                                ? "matche intéressant aujourd'hui"
                                : "interesting match today"}
                            </strong>{" "}
                          </p>
                        )}
                        {lang === "fr"
                          ? "Veuillez indiquer la fraction de votre capital que vous voulez allouer aux paris sportifs pour chaque match"
                          : "Please indicate the fraction of your bankroll that you want to allocate to sports betting for each match"}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="capital-desc">
                        {lang === "fr"
                          ? "Saisissez votre capital disponible"
                          : "Enter your available bankroll"}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="number"
                          value={betAmounts["user_bet"] || ""}
                          onChange={(e) =>
                            handleBetAmountChange(
                              "user_bet",
                              parseInt(e.target.value)
                            )
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleBankrollSubmit();
                            }
                          }}
                          className="bet-input"
                          style={{
                            borderRadius: "20px",
                            padding: "10px 15px",
                            fontSize: "26px",
                            color: "#00FFFF",
                            border: "1px solid #ccc",
                            width: "30%",
                            boxSizing: "border-box",
                            marginBottom: "20px",
                            marginTop: "20px",
                            fontWeight: "bold",
                          }}
                        />
                        <button
                          className="bouton2"
                          onClick={handleBankrollSubmit}
                          style={{
                            backgroundColor: "#001f3f",
                            color: "#FFEB3B",
                            borderRadius: "10px",
                            textDecoration: "none",
                            fontWeight: "bold",
                            fontSize: "16px",
                            cursor: "pointer",
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = "#FFEB3B";
                            e.currentTarget.style.color = "#001f3f";
                            e.currentTarget.style.boxShadow =
                              "0 0 15px #FFEB3B, 0 0 30px #FFEB3B, 0 0 45px #FFEB3B"; // Effet néon jaune
                            e.currentTarget.style.border = "none";
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = "#001f3f";
                            e.currentTarget.style.color = "#FFEB3B";
                            e.currentTarget.style.boxShadow = "none";
                          }}
                        >
                          {lang === "fr" ? "Valider" : "Validate"}
                        </button>
                      </div>
                    </div>
                  )}
                  <br />
                  {bankroll !== null ? (
                    <div className="risk-section">
                      <p className="risk-label">
                        {lang === "fr" ? "Fraction à miser" : "Fraction to bet"}
                      </p>
                      <br />
                      <div className="risk-buttons">
                        <button
                          className={`risk-button ${
                            riskLevel === 1 / 50 ? "active" : ""
                          }`}
                          onClick={() => setRiskLevel(1 / 50)}
                        >
                          <p style={{ fontSize: "1.3em" }}>
                            {lang === "fr" ? "Faible" : "Low"}
                          </p>
                          <p style={{ fontSize: "0.9em" }}>(1/50)</p>
                        </button>
                        <button
                          className={`risk-button ${
                            riskLevel === 1 / 40 ? "active" : ""
                          }`}
                          onClick={() => setRiskLevel(1 / 40)}
                        >
                          <p style={{ fontSize: "1.3em" }}>
                            {lang === "fr" ? "Moyen" : "Medium"}
                          </p>
                          <p style={{ fontSize: "0.9em" }}>(1/40)</p>
                        </button>
                        <button
                          className={`risk-button ${
                            riskLevel === 1 / 30 ? "active" : ""
                          }`}
                          onClick={() => setRiskLevel(1 / 30)}
                        >
                          <p style={{ fontSize: "1.3em" }}>
                            {lang === "fr" ? "Élevé" : "High"}
                          </p>
                          <p style={{ fontSize: "0.9em" }}>(1/30)</p>
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <Log signup={false} signin={true} />
              )}

              <div
                className="img-container"
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <img
                  src={
                    uidControl
                      ? "./images/eliza_decolt.png"
                      : "./images/eliza_rose.png"
                  }
                  alt="img-log"
                  style={{
                    maxWidth: "180%",
                    boxShadow: uidControl
                      ? "0 0 15px #FFEB3B, 0 0 30px #FFEB3B, 0 0 45px #FFEB3B"
                      : "0 0 15px #FF00FF, 0 0 30px #FF00FF, 0 0 45px #FF00FF",
                    objectFit: "contain",
                    borderRadius: "180px",
                    marginLeft: uidControl ? "100px" : "100px",
                    border: uidControl
                      ? "3px solid #FFEB3B"
                      : "3px solid #FF00FF",
                    marginTop: uidControl ? "30px" : "",
                  }}
                />
              </div>
            </div>
            <div
              style={{ fontSize: "26px", color: "black", marginTop: "20px" }}
            >
              <p>
                "The question isn't who's going to let me; it's who's going to
                stop me." — Ayn Rand
              </p>
            </div>
          </div>
          {bankroll !== null ? (
            <p
              className="mt-12"
              style={{
                fontSize: "22px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "5px 10px",
                textAlign: "center",
                marginBottom: "0px",
                fontWeight: "bold",
              }}
            >
              {lang === "fr"
                ? `Une fois le pronostic établi, Eliza vous demandera d'indiquer la cote attribuée par les bookmakers au gagnant. Cela lui permettra de déterminer si un pari sur ce match est judicieux et, le cas échéant, de calculer le montant optimal à miser. Cette évaluation se base sur les probabilités fournies par Eliza, votre capital disponible, la fraction de mise souhaitée, ainsi que la cote indiquée. Les paris rentables à long terme seront signalés par son logo.`
                : `Once the prediction is made, Eliza will ask you to indicate the odds assigned by the bookmakers to the winner. This will allow her to determine if a bet on this match is worthwhile and, if so, to calculate the optimal amount to bet. This evaluation is based on the probabilities provided by Eliza, your available bankroll, the desired bet fraction, and the indicated odds. Long-term profitable bets will be indicated by her logo.`}
            </p>
          ) : null}

          {matches.map((matchGroup, index) => (
            <div key={index} className="mb-20">
              <p
                className="mt-20 mb-0"
                style={{ fontWeight: "bold", fontSize: "30px" }}
              >
                {formatDateString(matchGroup.date, lang)}
              </p>
              <br />
              {Object.entries(
                matchGroup.matches.reduce((acc, match) => {
                  const key = `${match.tournoi}-${match.round}`;
                  if (!acc[key]) {
                    acc[key] = [];
                  }
                  acc[key].push(match);
                  return acc;
                }, {} as { [key: string]: Match[] })
              ).map(([key, matches]) => (
                <div key={key} className="ml-10">
                  <p
                    className="mb-20 mt-20"
                    style={{ fontWeight: "bold", fontSize: "23px" }}
                  >
                    {matches[0].tournoi} - {matches[0].round}
                  </p>
                  <ul>
                    {matches.map((match, matchIndex) => (
                      <li
                        key={matchIndex}
                        style={{
                          position: "relative",
                          marginBottom: "80px",
                          backgroundColor: "#001f3f",
                          color: "white",
                          borderRadius: "20px",
                          padding: "20px 50px",
                          marginLeft: "200px",
                          marginRight: "100px",
                          overflow: "visible",
                          borderTop: "2px solid #00d8ff",
                          borderBottom: "2px solid #00d8ff",
                        }}
                      >
                        <span
                          style={{
                            position: "absolute",
                            top: "0",
                            bottom: "0",
                            left: "10%",
                            width: "0",
                            borderLeft: "2px dashed white",
                            transform: "translateX(50%)",
                          }}
                        ></span>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "0",
                            width: "50px",
                            height: "120px",
                            backgroundColor: "black",
                            borderRadius: "0 80px 80px 0",
                            transform: "translateY(-50%)",
                          }}
                        ></div>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "0",
                            width: "50px",
                            height: "120px",
                            backgroundColor: "black",
                            borderRadius: "80px 0 0 80px",
                            transform: "translateY(-50%)",
                          }}
                        ></div>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "50px",
                            fontSize: "25px",
                            fontWeight: "bold",
                            marginLeft: "50px",
                          }}
                        >
                          {playerNames[match.id]}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "5px",
                            fontSize: "22px",
                            lineHeight: "2",
                            marginLeft: "50px",
                            marginTop: "70px",
                          }}
                        >
                          {resultShownMatches.includes(match.id) ? (
                            <>
                              <p style={{ color: "#00FFFF" }}>
                                {resultPhrases[match.id]}
                              </p>

                              {!resultShownBets.includes(match.id) && (
                                <>
                                  <input
                                    type="number"
                                    value={odds[match.id] || ""}
                                    onChange={(e) =>
                                      setOdds({
                                        ...odds,
                                        [match.id]: parseFloat(e.target.value),
                                      })
                                    }
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        handlePlaceBet(match);
                                      }
                                    }}
                                    placeholder={
                                      lang === "fr"
                                        ? "Côte du book"
                                        : "Book odds"
                                    }
                                    style={{
                                      marginRight: "20px",
                                      marginLeft: "120px",
                                      color: "#00FFFF",
                                      marginTop: "30px",
                                      borderColor: "#FFEB3B",
                                      textAlign: "center",
                                      border: "1px solid #ccc",
                                      fontFamily: "Orbitron",
                                      fontWeight: "bold",
                                      width: "30%",
                                    }}
                                    size={8}
                                  />

                                  <button
                                    className="button3"
                                    onClick={() => handlePlaceBet(match)}
                                  >
                                    <p>
                                      {lang === "fr" ? "Voir mise" : "View bet"}
                                    </p>
                                  </button>
                                </>
                              )}
                              {resultBetPhrases[match.id] !== undefined &&
                              resultBetPhrases[match.id] !== "" ? (
                                <div>
                                  <p style={{ color: "#00FFFF" }}>
                                    {resultBetPhrases[match.id]}
                                  </p>
                                  {!showMiserQuandMemeButton[match.id] ? (
                                    <>
                                      <br />
                                      <button
                                        className="button3"
                                        onClick={() =>
                                          handleMiserQuandMemeClick(match.id)
                                        }
                                      >
                                        {lang === "fr"
                                          ? "Miser quand même"
                                          : "Bet anyway"}
                                      </button>
                                    </>
                                  ) : null}
                                  {showResultBetPhrase2[match.id] && (
                                    <p style={{ color: "#00FFFF" }}>
                                      {resultBetPhrases2[match.id]}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <p style={{ color: "#00FFFF" }}>
                                  {resultBetPhrases2[match.id]}
                                </p>
                              )}
                            </>
                          ) : (
                            <button
                              className="button3"
                              style={{ width: "200px" }}
                              onClick={async () => {
                                if (!uidControl) {
                                  const confirmation = window.confirm(
                                    lang === "fr"
                                      ? "Vous devez être connecté pour accéder aux pronostiques"
                                      : "You must be logged in to access the predictions"
                                  );
                                  if (confirmation) {
                                    window.location.href = "/connexion";
                                    alert(
                                      lang === "fr"
                                        ? "Redirection vers la page de connexion ou d'inscription"
                                        : "Redirecting to the login or sign-up page"
                                    );
                                  }
                                } else {
                                  if (match.voting.worthit) {
                                    if (abo) {
                                      handleShowResult(match);
                                    } else {
                                      const confirmation = window.confirm(
                                        lang === "fr"
                                          ? "Vous devez être membre premium pour accéder à ce pronostique"
                                          : "You must be a premium member to access this prediction"
                                      );
                                      if (confirmation) {
                                        try {
                                          const stripe = await stripePromise;

                                          const { data: session } =
                                            await axios.post(
                                              `${process.env.REACT_APP_API_URL}api/user/create-checkout-session`,
                                              {},
                                              { withCredentials: true }
                                            );

                                          const result =
                                            await stripe?.redirectToCheckout({
                                              sessionId: session.id,
                                            });

                                          if (result?.error) {
                                          }
                                        } catch (error) {}
                                      }
                                    }
                                  } else {
                                    handleShowResult(match);
                                  }
                                }
                              }}
                            >
                              <p>
                                {lang === "fr"
                                  ? "Voir résultat"
                                  : "View result"}
                              </p>
                            </button>
                          )}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
          <div>
            <p
              className="mt-22px"
              style={{
                fontSize: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "5px 10px",
                textAlign: "center",
                marginBottom: "-30px",
                marginTop: "80px",
                fontWeight: "bold",
              }}
            >
              {emptyData === null
                ? lang === "fr"
                  ? "Aucun match ATP n'est programmé aujourd'hui pour être analysé."
                  : "No ATP match is scheduled today for analysis."
                : emptyData
                ? null
                : null}
            </p>
          </div>
        </section>
      )}
    </>
  );
};
