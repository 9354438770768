import React, { useState, useEffect } from "react";

export const Prevention = () => {
  const [lang, setLang] = useState('fr');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang !== 'fr-FR' && userLang !== 'fr') {
      setLang('en');
    }
  }, []);

  return (
    <section
      style={{
        borderTop: "3px solid #000",
        borderBottom: "3px solid #000",
        padding: "5px 0",
        margin: "20px 0",
      }}
    >        <div
    style={{
      borderTop: "12px solid #00FFFF", // Magenta néon pour la barre supérieure
      boxShadow: "0 0 15px #00FFFF, 0 0 30px #00FFFF, 0 0 45px #00FFFF", // Effet néon magenta
      borderRadius: "10px", // Arrondi les coins pour un look plus doux
      marginTop: "100px",
      marginBottom: "30px",
    }}
  />
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px 10px",
          textAlign: "center",
          marginBottom: "0px",
          fontWeight: "bold",
        }}
        className="mt-1 mb-1"
      >
        <div>
          <p>
            {lang === 'fr'
              ? "Eliza n'est pas conseillère en investissement et ne garantit pas la richesse à ses utilisateurs. Ne pariez que ce que vous pouvez vous permettre de perdre. L'addiction aux jeux d'argent peut entraîner des conséquences graves, telles que l'endettement, l'isolement social, voire la dépression. Si vous pensez avoir un problème de jeu, il est important de demander de l'aide. Vous pouvez contacter Joueurs Info Service au 09 74 75 13 13 pour obtenir des conseils gratuits. Il s'agit d'un appel non surtaxé et confidentiel."
              : "Eliza is not an investment advisor and does not guarantee wealth for its users. Only bet what you can afford to lose. Gambling addiction can lead to serious consequences, such as debt, social isolation, and even depression. If you think you have a gambling problem, it is important to seek help. You can contact Gambling Helpline at 09 74 75 13 13 for free and confidential advice."}
          </p>

          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <a
              href="https://www.joueurs-info-service.fr/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "inline-block",
                backgroundColor: "#001f3f",
                
                color: "#FFEB3B",
                padding: "10px 20px",
                borderRadius: "60px",
                textDecoration: "none",
                border: "2px solid #00d8ff",
                boxShadow:
                  "0 4px 15px rgba(0, 216, 255, 0.75), 0 0 30px rgba(0, 216, 255, 0.6)",
                fontWeight: "bold",
                fontSize: "16px",
                transition:
                  "background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease",
                cursor: "pointer",
                textShadow: "0 0 8px #00d8ff, 0 0 15px #00d8ff", // camelCase pour textShadow
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#00FFFF";
                e.currentTarget.style.color = "#001f3f";
                e.currentTarget.style.boxShadow =
                  "0 0 15px #00FFFF, 0 0 30px #00FFFF, 0 0 45px #00FFFF"; // Effet néon jaune
                e.currentTarget.style.border = "none";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#001f3f";
                e.currentTarget.style.color = "#FFEB3B";
                e.currentTarget.style.boxShadow =
                  "0 4px 15px rgba(0, 216, 255, 0.75), 0 0 30px rgba(0, 216, 255, 0.6)"; // Retire l'effet néon quand la souris quitte
                e.currentTarget.style.border = "2px solid #00d8ff";
              }}
            >
              {lang === 'fr' ? "Aides addictions" : "Addiction Help"}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
