import React, { useEffect, useState } from "react";
import { Prevention } from "./Prevention";

export const Privacy: React.FC = () => {
  const [language, setLanguage] = useState<"fr" | "en">("fr");

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang !== "fr-FR" && userLang !== "fr") {
      setLanguage("en");
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML";
    script.async = true;
    document.head.appendChild(script);
  }, []);

  const translations = {
    fr: {
      title: "Politique de Confidentialité",
      introduction:
        "Chez Elizabet, nous nous engageons à protéger la confidentialité et la sécurité de vos informations personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons, partageons et protégeons vos données lorsque vous utilisez notre site web, conformément aux lois françaises et au Règlement Général sur la Protection des Données (RGPD).",
      sections: {
        collectionTitle: "1. Collecte des Informations",
        collectionText:
          "Nous collectons différentes informations lorsque vous utilisez notre site, y compris des données personnelles telles que votre nom, adresse e-mail, et informations de paiement. Ces informations sont collectées lorsque vous créez un compte, effectuez des transactions, ou interagissez avec notre site. Nous collectons également des données d'utilisation du site par le biais de cookies et de technologies similaires.",
        usageTitle: "2. Utilisation des Informations",
        usageText: "Les informations collectées sont utilisées pour :",
        usageList: [
          "Fournir et améliorer nos services",
          "Traiter vos transactions",
          "Communiquer avec vous concernant votre compte",
          "Personnaliser votre expérience sur notre site",
          "Respecter nos obligations légales",
        ],
        sharingTitle: "3. Partage des Informations",
        sharingText:
          "Nous ne vendons ni ne louons vos informations personnelles à des tiers. Nous pouvons partager vos informations avec des partenaires de confiance qui nous aident à fournir nos services, tels que les prestataires de services de paiement. Nous pouvons également divulguer vos informations si la loi l'exige ou pour protéger nos droits, ou encore dans le cadre d'une procédure légale.",
        securityTitle: "4. Sécurité des Données",
        securityText:
          "Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles appropriées pour protéger vos informations contre tout accès, divulgation, modification ou destruction non autorisés. Cependant, aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée. Nous nous efforçons de protéger vos données personnelles, mais nous ne pouvons garantir leur sécurité absolue.",
        rightsTitle: "5. Vos Droits",
        rightsText:
          "Conformément au RGPD et à la législation française, vous avez le droit d'accéder à vos informations personnelles, de les rectifier, de les supprimer, de restreindre leur traitement et de vous opposer à leur traitement. Vous avez également le droit à la portabilité des données. Pour exercer ces droits, veuillez nous contacter à l'adresse suivante : contact@elizabet.io. Nous répondrons à votre demande dans les meilleurs délais, conformément à la législation applicable.",
        cookiesTitle: "6. Cookies et Technologies Similaires",
        cookiesText:
          "Nous utilisons des cookies et des technologies similaires pour améliorer votre expérience sur notre site, analyser l'utilisation de nos services et personnaliser le contenu. Vous pouvez configurer votre navigateur pour refuser les cookies, mais cela pourrait affecter certaines fonctionnalités de notre site.",
        transfersTitle: "7. Transferts Internationaux de Données",
        transfersText:
          "Vos informations peuvent être transférées et traitées dans des pays situés en dehors de l'Espace économique européen (EEE). Dans ce cas, nous nous assurons que des mesures de protection adéquates sont en place pour protéger vos informations conformément à cette politique de confidentialité et à la législation applicable.",
        modificationsTitle: "8. Modifications de la Politique de Confidentialité",
        modificationsText:
          "Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Nous vous informerons de toute modification en publiant la nouvelle politique de confidentialité sur notre site. Les modifications entreront en vigueur dès leur publication. Nous vous encourageons à consulter régulièrement cette page pour vous tenir informé des mises à jour.",
        contactTitle: "9. Contact",
        contactText:
          "Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à l'adresse suivante : contact@elizabet.io.",
      },
    },
    en: {
      title: "Privacy Policy",
      introduction:
        "At Elizabet, we are committed to protecting the privacy and security of your personal information. This privacy policy explains how we collect, use, share, and protect your data when you use our website, in accordance with French laws and the General Data Protection Regulation (GDPR).",
      sections: {
        collectionTitle: "1. Information Collection",
        collectionText:
          "We collect various information when you use our site, including personal data such as your name, email address, and payment information. This information is collected when you create an account, make transactions, or interact with our site. We also collect site usage data through cookies and similar technologies.",
        usageTitle: "2. Use of Information",
        usageText: "The information collected is used to:",
        usageList: [
          "Provide and improve our services",
          "Process your transactions",
          "Communicate with you regarding your account",
          "Personalize your experience on our site",
          "Comply with our legal obligations",
        ],
        sharingTitle: "3. Information Sharing",
        sharingText:
          "We do not sell or rent your personal information to third parties. We may share your information with trusted partners who assist us in providing our services, such as payment service providers. We may also disclose your information if required by law or to protect our rights, or in the context of legal proceedings.",
        securityTitle: "4. Data Security",
        securityText:
          "We implement appropriate technical and organizational security measures to protect your information against unauthorized access, disclosure, modification, or destruction. However, no method of transmission over the Internet or electronic storage is completely secure. We strive to protect your personal data, but we cannot guarantee its absolute security.",
        rightsTitle: "5. Your Rights",
        rightsText:
          "In accordance with the GDPR and French law, you have the right to access, correct, delete, restrict the processing of, and object to the processing of your personal information. You also have the right to data portability. To exercise these rights, please contact us at the following address: contact@elizabet.io. We will respond to your request as soon as possible, in accordance with applicable law.",
        cookiesTitle: "6. Cookies and Similar Technologies",
        cookiesText:
          "We use cookies and similar technologies to enhance your experience on our site, analyze the use of our services, and personalize content. You can configure your browser to refuse cookies, but this may affect certain features of our site.",
        transfersTitle: "7. International Data Transfers",
        transfersText:
          "Your information may be transferred and processed in countries outside the European Economic Area (EEA). In this case, we ensure that adequate protection measures are in place to protect your information in accordance with this privacy policy and applicable law.",
        modificationsTitle: "8. Changes to the Privacy Policy",
        modificationsText:
          "We reserve the right to modify this privacy policy at any time. We will notify you of any changes by posting the new privacy policy on our site. Changes will take effect immediately upon posting. We encourage you to review this page regularly to stay informed of any updates.",
        contactTitle: "9. Contact",
        contactText:
          "If you have any questions regarding this privacy policy, please contact us at the following address: contact@elizabet.io.",
      },
    },
  };

  const { title, introduction, sections } = translations[language];

  return (
    <section className="mb-5 mt-10" style={{ margin: "0 20px" }}>
      <div>
        <h1
          className="admi-titre1"

        >
          {title}
        </h1>
        <p style={{ fontSize: "22px", marginLeft: "20px", marginTop: "20px"}}>
          {introduction}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px"}}>
          {sections.collectionTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px"}}>
          {sections.collectionText}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px"}}>
          {sections.usageTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px"}}>
          {sections.usageText}
          <ul style={{ marginLeft: "40px", listStyleType: "disc" }}>
            {sections.usageList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px"}}>
          {sections.sharingTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px"}}>
          {sections.sharingText}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px"}}>
          {sections.securityTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px"}}>
          {sections.securityText}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px"}}>
          {sections.rightsTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px"}}>
          {sections.rightsText}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px"}}>
          {sections.cookiesTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px"}}>
          {sections.cookiesText}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px"}}>
          {sections.transfersTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px"}}>
          {sections.transfersText}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px"}}>
          {sections.modificationsTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px"}}>
          {sections.modificationsText}
        </p>

        <h2 className="admi-titre1" style={{ fontSize: "33px"}}>
          {sections.contactTitle}
        </h2>
        <p style={{ fontSize: "22px", marginLeft: "20px"}}>
          {sections.contactText}
        </p>

        <div className="flex justify-left" style={{ marginTop: "80px" }}>
          <Prevention />
        </div>
      </div>
    </section>
  );
};
